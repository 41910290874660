import photo1 from '../images/photo_1.jpg';
import photo2 from '../images/photo_2.jpg';
import photo3 from '../images/photo_3.jpg';
import photo4 from '../images/photo_4.jpg';
import feedback1 from '../images/feedback_1.png';
import feedback2 from '../images/feedback_2.png';
import feedback3 from '../images/feedback_3.png';

export const photos = [
    photo1,
    photo2,
    photo3,
    photo4
  ];

  export const reviewsPhotos = [
    feedback1,
    feedback2,
    feedback3
  ];